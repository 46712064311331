












































































import Vue from "vue";
import CountryFlag from "vue-country-flag";

import { localeTable } from "#root/lib/localeTable";
import { t } from "#root/lib/utils";

import { LocaleKeys } from "#mws/data";
import CookieManager from "#root/lib/cookieManager";
import PersistentModule from "#root/store/persistent";

export default Vue.extend({
  name: "LanguageButton",
  components: { CountryFlag },
  watch: {
    select: function (value: number) {
      const locale = this.localeList[value];
      PersistentModule.locale = locale;
      CookieManager.set("miniworlds_locale", value, 30 * 24 * 60 * 60);
      this.$emit("localeChanged");
    },
  },
  methods: {
    t: t,
  },
  data: () => ({
    localeList: Object.getOwnPropertyNames(localeTable).slice(0, -1),
    select: PersistentModule.locale,
    lk: LocaleKeys,
  }),
});
